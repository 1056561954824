import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FaBitcoin, FaEthereum } from 'react-icons/fa'; // Icons for Crypto
import { SiPaytm, SiPhonepe, SiGooglepay, SiTether } from 'react-icons/si'; // Icons for UPI
import { BsBank } from 'react-icons/bs';
import * as userService from '../../api/userDetails';
import { useState, useEffect } from 'react';

// CSS in JS for the icon styles
const iconStyles = {
  marginRight: '10px',
  marginLeft: '10px',
  background: 'linear-gradient(to right, #ff9966, #ff5e62)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const cardStyles = {
  border: '1px solid lightgray',
  borderRadius: '5px',
  padding: '20px',
  margin: '10px',
};
const WithdrawPage = () => {
  const [inrStatus, setInrStatus] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const result = await userService.fetchUserINRStatus();

        const user_inr_status = result.data.is_inr_deposit;
        setInrStatus(user_inr_status);
        setIsDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserStatus();
  }, []);

  return (
    isDataLoaded && (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Card style={cardStyles} className="mb-4">
              <LinkContainer to="/fiat-withdraw">
                <Button variant="primary" size="lg" block>
                  Withdraw INR to Bank
                </Button>
              </LinkContainer>
              <div className="d-flex justify-content-around mt-3">
                <BsBank size={34} style={iconStyles} />
              </div>
            </Card>
          </Col>

          {!inrStatus && (
            <Col xs={12} md={6}>
              <Card style={cardStyles} className="mb-4">
                <LinkContainer to="/crypto-withdraw">
                  <Button variant="secondary" size="lg" block>
                    Withdraw Crypto
                  </Button>
                </LinkContainer>
                <div className="d-flex justify-content-around mt-3">
                  <FaBitcoin size={34} style={iconStyles} />
                  <FaEthereum size={34} style={iconStyles} />
                  <SiTether size={34} style={iconStyles} />
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    )
  );
};

export default WithdrawPage;
