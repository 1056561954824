import './event.scss';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import * as eventServe from '../../api/event';
import * as loginServe from '../../api/auth';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as dateFormate from '../../utils/dateformate';
import * as walletService from '../../api/wallet';
import { Button, Form, Alert } from 'react-bootstrap';
import { updateBalance, subtractFromBalance } from '../../store/wallet-slice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { IoFootballOutline, IoAirplaneOutline } from 'react-icons/io5';
import { IoBasketball } from 'react-icons/io5';
import {
  MdSportsTennis,
  MdSportsCricket,
  MdBadminton,
  MdBasketballHoopOutline,
  MdSmartphone,
  MdEvent,
} from 'react-icons/md';
import { TiChartLine } from 'react-icons/ti';
import { AiFillYoutube, AiOutlineBullseye } from 'react-icons/ai';
import { GiTrophy, GiFilmProjector, GiVote, GiBullseye } from 'react-icons/gi';
import { RiTrophyLine, RiInstagramLine } from 'react-icons/ri';
import { WiDayStormShowers, WiDayHail, WiDaySunny } from 'react-icons/wi';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';
import moment from 'moment';

const Event = props => {
  useDocumentTitle('Event Details');

  const divRef = useRef(null);

  const isLoggedIn = useSelector(state => state.token.isTokenExist);
  const [eventDetails, setEventDetails] = useState({});
  const [outcomeSelect, setOutcomeSelect] = useState(null);
  const { id } = useParams();
  const [betAmount, setBetAmount] = useState({ bet_amount: '' });
  const [userBalance, setUserBalance] = useState(0);
  const [isFirstBet, setisFirstBet] = useState(false);
  const [bonusBalance, setBonusBalance] = useState(0);
  const [winBalance, setWinBalance] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const [betDetails, setBetDetails] = useState({
    yesPercentage: 0,
    noPercentage: 0,
  });

  const [formattedEventName, setFormattedEventName] = useState('');

  const formatDateToLocal = date => {
    // Here you can use your logic or libraries like moment.js to format the date
    return moment(date).format('hh:mmA'); // 10:30AM
  };

  const dispatch = useDispatch();
  const selectOutcomeHandle = value => {
    setOutcomeSelect(value);
  };

  const getEventDeailsById = async () => {
    try {
      dispatch(showLoader());
      const data = await eventServe.getEventById(id);

      setEventDetails(data?.data);
      // Replace placeholders with actual, localized dates
      const formattedEventName = data.data.event_name
        .replace('{endDate}', formatDateToLocal(data.data.event_end_date))
        .replace(
          '{settlementDate}',
          formatDateToLocal(data.data.event_settlement_date),
        );

      setFormattedEventName(formattedEventName);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const getBetDetailsByEventId = async () => {
    try {
      dispatch(showLoader());
      const data = await eventServe.getBetsForAnEvent(id);
      if (data.status) {
        const yesBetsTotal = data.data.data.yesBetsTotal;

        const noBetsTotal = data.data.data.noBetsTotal;
        const total = yesBetsTotal + noBetsTotal;
        const yesPercentage =
          total === 0 ? 50 : Math.round((yesBetsTotal / total) * 100);
        const noPercentage =
          total === 0 ? 50 : Math.round((noBetsTotal / total) * 100);

        const multiplier_yes = data.data.data.multiplier_yes;
        const multiplier_no = data.data.data.multiplier_no;

        setBetDetails({
          yesPercentage,
          noPercentage,
          multiplier_yes,
          multiplier_no,
          yesBetsTotal,
          noBetsTotal,
        });
      } else {
        setBetDetails({
          yesPercentage: 50,
          noPercentage: 50,
          multiplier_yes: 1,
          multiplier_no: 1,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleAmountChange = event => {
    const inputVal = event.target.value;

    // If input is empty, set bet amount to an empty string
    if (inputVal === '') {
      setBetAmount({ bet_amount: '' });
    } else {
      setBetAmount({ bet_amount: Number(inputVal) });
    }
  };

  const createBet = async e => {
    try {
      e.preventDefault();
      if (eventDetails?.status !== 'open') {
        toast.info('Prediction period for this event is closed.');
        return;
      }

      dispatch(showLoader());

      const wallet = await walletService.getWalletBalance();
      //const userBalance = wallet.data.mainBalance + wallet.data.bonusBalance;
      const mainBalance = convertUsdToInr(wallet.data.mainBalance);
      const bonusBalance = convertUsdToInr(wallet.data.bonusBalance);
      const winBalance = convertUsdToInr(wallet.data.winBalance);

      //setUserBalance(userBalance);
      if (Number(userBalance) < Number(betAmount.bet_amount)) {
        toast.info(`You do not have sufficent funds`);
        return;
      }
      const betAmount_inUSD = convertInrToUsd(betAmount.bet_amount);
      //console.log(betAmount_inUSD, "betAmount_inUSD")

      const data = { bet_amount: betAmount_inUSD, bet_result: outcomeSelect };

      //const data = { ...betAmount, bet_result: outcomeSelect };
      const betplaced = await eventServe.createBet(id, data);
      if (betplaced) {
        let AvailableBalance = 0;

        AvailableBalance =
          mainBalance + winBalance + bonusBalance - betAmount.bet_amount;

        setUserBalance(AvailableBalance);
        dispatch(subtractFromBalance(convertInrToUsd(betAmount.bet_amount)));
        setisFirstBet(false);
        getBetDetailsByEventId();
        //await walletService.placeBet(wallet._id, betAmount.bet_amount);
      }
      toast.success('Your prediction has been placed successfully');
    } catch (error) {
      console.log(error);
      toast.error(
        'Some error occurred. Please wait a while before trying again.',
      );
    } finally {
      dispatch(hideLoader());
      setBetAmount({ bet_amount: '' });
    }
  };
  const getUserBalance = async () => {
    try {
      const user_wallet = await walletService.getWalletBalance();

      //const balance =user_wallet.data.mainBalance + user_wallet.data.bonusBalance;
      //console.log(balance);
      const betBalanceINR = convertUsdToInr(user_wallet.data.betBalance);

      setUserBalance(betBalanceINR);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserDetails = async () => {
    try {
      const user = await loginServe.getBalance();
      setisFirstBet(user.data.is_first_bet);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryIcon = () => {
    switch (eventDetails.icon_name) {
      case 'cricket':
        return <MdSportsCricket size={50} color="#FF0000" />;
      case 'football':
        return <IoFootballOutline size={50} color="#00FF00" />;
      case 'tennis':
        return <MdSportsTennis size={50} color="#00FF00" />;
      case 'badminton':
        return <MdSportsTennis size={50} color="#FFFF00" />;
      case 'basketball':
        return <IoBasketball size={50} color="#FFFF00" />;
      case 'stocks':
        return <TiChartLine size={50} color="#FF0000" />;
      case 'sports':
        return <MdSmartphone size={50} color="#FF0000" />;
      case 'crypto':
        return <MdSmartphone size={50} color="#FFFF00" />;
      case 'award':
        return <GiTrophy size={50} color="#FFFF00" />;
      case 'movies':
        return <MdEvent size={50} color="#0000FF" />;
      case 'film':
        return <GiFilmProjector size={50} color="#0000FF" />;
      case 'event':
        return <MdEvent size={50} color="#FFFF00" />;
      case 'vote':
        return <GiVote size={50} color="#FF0000" />;
      case 'trophy':
        return <GiTrophy size={50} color="#00FF00" />;
      case 'smartphone':
        return <MdSmartphone size={50} color="#0000FF" />;
      case 'flight':
        return <IoAirplaneOutline size={50} color="#FFFF00" />;
      case 'weather':
        return <WiDayStormShowers size={50} color="#FF0000" />;
      case 'weather2':
        return <WiDayHail size={50} color="#00FF00" />;
      case 'weather3':
        return <WiDaySunny size={50} color="#0000FF" />;
      case 'instagram':
        return <RiInstagramLine size={50} color="#FF0000" />;
      case 'youtube':
        return <AiFillYoutube size={50} color="#00FF00" />;
      case 'airplane':
        return <IoAirplaneOutline size={50} color="#0000FF" />;
      default:
        return <GiBullseye size={50} color="#808080" />;
    }
  };
  useEffect(() => {
    getBetDetailsByEventId();
    getEventDeailsById();
    divRef.current.scrollIntoView();

    if (isLoggedIn) {
      getUserDetails();
      getUserBalance();
    }
    window.scrollTo(0, 0);
  }, [id, isLoggedIn]);

  /*   useEffect(() => {
    if (isFirstBet) {
      setBetAmount({ bet_amount: bonusBalance });
    }
  }, [isFirstBet, bonusBalance]); */

  return (
    <div className="container trade-event" ref={divRef}>
      <div className="row mt-3">
        <div className="col-12 col-md-5 col-lg-4">
          <div className="price-card p-4">
            <div className="d-flex align-items-center">
              <span className="ms-2 fw-bold">{formattedEventName}</span>
            </div>
            <div className="row align-items-center justify-content-around justify-content-md-start mt-4">
              <div className="overlay bottom-overlay">
                <div className="overlay-content-bottom d-flex">
                  <div className="progress-bar-container">
                    <span className="progress-label" style={{ width: '20px' }}>
                      {betDetails.yesPercentage}%
                    </span>
                    <div
                      className="progress-bar-fill-yes"
                      style={{
                        width: `${betDetails.yesPercentage}%`,
                        flexGrow: '1',
                      }}
                    ></div>
                  </div>

                  <div className="progress-bar-container">
                    <span className="progress-label" style={{ width: '20px' }}>
                      {betDetails.noPercentage}%
                    </span>

                    <div
                      className="progress-bar-fill-no"
                      style={{
                        width: `${betDetails.noPercentage}%`,
                        flexGrow: '1',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <Form onSubmit={createBet}>
              {/* Outcome for betting */}
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold mb-0">Outcome</Form.Label>
                <div className="d-flex align-items-center mt-2">
                  <Button
                    variant={
                      outcomeSelect === 'Yes' ? 'success' : 'outline-success'
                    }
                    className="me-2 w-50"
                    onClick={() => setOutcomeSelect('Yes')}
                  >
                    {outcomeSelect === 'Yes' && (
                      <i className="bi bi-check-circle-fill me-2"></i>
                    )}
                    Yes
                  </Button>
                  <Button
                    variant={
                      outcomeSelect === 'No' ? 'danger' : 'outline-danger'
                    }
                    className="w-50"
                    onClick={() => setOutcomeSelect('No')}
                  >
                    {outcomeSelect === 'No' && (
                      <i className="bi bi-check-circle-fill me-2"></i>
                    )}
                    No
                  </Button>
                </div>
              </Form.Group>

              {/* Betting amount */}
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold mb-0">Amount</Form.Label>
                <div className="d-flex align-items-center mt-2">
                  <Form.Control
                    type="number"
                    name="bet_amount"
                    placeholder="₹ 0"
                    min={10}
                    max={userBalance}
                    value={betAmount?.bet_amount}
                    onChange={handleAmountChange}
                    required
                    className="amount-input w-100 rounded p-2"
                    disabled={eventDetails?.status !== 'open'}
                  />
                  <small className="ms-2">
                    <small className="ms-2">
                      Available Balance: ₹{Number(userBalance).toFixed(0)}
                    </small>
                  </small>
                </div>
              </Form.Group>
              {/* Submit button */}
              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-2"
                disabled={
                  !isLoggedIn ||
                  !outcomeSelect ||
                  betAmount.bet_amount === '' ||
                  dateFormate?.compareDates(eventDetails?.event_end_date) ===
                    'lesser' ||
                  eventDetails?.status !== 'open'
                }
              >
                {isLoggedIn
                  ? dateFormate?.compareDates(eventDetails?.event_end_date) ===
                      'lesser' || eventDetails?.status !== 'open'
                    ? 'Event Closed'
                    : 'Submit'
                  : 'Login to Predict'}
              </Button>
            </Form>

            <hr />
            {/* calculation */}
            <div className="row">
              <p className="col-6 text-secondary"> Yes Multiplier</p>
              <p className="col-6 text-end">
                {betDetails.multiplier_yes}
                <span className="ms-2">
                  <i
                    className="bi bi-info-circle app-text-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-delay="1"
                    title="Note that the multiplier will change as more bets are placed. Your win amount will depend on final multiplier."
                  ></i>
                </span>
              </p>
              <p className="col-6 text-secondary">No Multiplier</p>
              <p className="col-6 text-end">
                {betDetails.multiplier_no}
                <span className="ms-2">
                  <i
                    className="bi bi-info-circle app-text-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-delay="1"
                    title="Note that the multiplier will change as more bets are placed. Your win amount will depend on final multiplier."
                  ></i>
                </span>
              </p>
              <p className="col-6 text-secondary">Total Invested</p>
              <p className="col-6 text-end">
                ₹
                {convertUsdToInr(
                  betDetails.yesBetsTotal + betDetails.noBetsTotal,
                ).toFixed(0)}
              </p>
              <p
                className="col-12 text-secondary"
                style={{ fontStyle: 'italic' }}
              >
                {' '}
                Note that the multiplier will change as more bets are placed.
                Your win amount will depend on the final multiplier when the
                event is closed.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 col-lg-8 order-md-first">
          {/* event title */}
          <div className="row">
            {/* <div className="col-12 text-center">
              <img
                src={eventDetails?.image?.s3Url}
                alt="icon"
                className="w-100"
              />
            </div> */}
            <div className="col-md-12 mt-2 mt-md-0">
              <div className="d-flex justify-content-between align-items-center">
                <span className="app-bg-grey p-2 rounded">
                  Event Ends :{' '}
                  {dateFormate?.formatTopDate(eventDetails?.event_end_date)}
                </span>
              </div>
              <h3 className="fw-bold fs-4 mt-3 mb-0">
                {getCategoryIcon()} {formattedEventName}
              </h3>
            </div>
          </div>
          <hr />
          {/* event description */}
          <div className="mt-2">
            <h4 className="app-text-primary">Market Resolution</h4>
            <hr />
            {/* <pre> {eventDetails?.event_description}</pre> */}
            <pre
              dangerouslySetInnerHTML={{
                __html: eventDetails?.event_description,
              }}
            />
          </div>
          {/* Market Timeline */}
          <div className="mt-2 mb-4">
            <h4 className="app-text-primary">Market Timeline</h4>
            <hr />
            <div className="row">
              <div className="col-2 col-lg-1">
                {dateFormate?.compareDates(eventDetails?.event_start_date) ===
                'lesser' ? (
                  <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                ) : (
                  <i className="bi bi-circle app-text-primary fs-4"></i>
                )}
              </div>
              <div className="col-10 col-lg-11">
                <h5 className="app-text-primary">Event Start</h5>
                <p className="text-secondary">
                  {dateFormate?.formatDate(eventDetails?.event_start_date)}
                </p>
              </div>
              <div className="col-2 col-lg-1">
                {dateFormate?.compareDates(eventDetails?.event_end_date) ===
                'lesser' ? (
                  <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                ) : (
                  <i className="bi bi-circle app-text-primary fs-4"></i>
                )}{' '}
              </div>
              <div className="col-10 col-lg-11">
                <h5 className="app-text-primary">Event Closure</h5>
                <p className="text-secondary">
                  {dateFormate?.formatDate(eventDetails?.event_end_date)}
                </p>
              </div>
              <div className="col-2 col-lg-1">
                {dateFormate?.compareDates(
                  eventDetails?.event_settlement_date,
                ) === 'lesser' ? (
                  <i className="bi bi-check-circle-fill app-text-primary fs-4"></i>
                ) : (
                  <i className="bi bi-circle app-text-primary fs-4"></i>
                )}{' '}
              </div>
              <div className="col-10 col-lg-11">
                <h5 className="app-text-primary">Event Settlement </h5>
                <p className="text-secondary">
                  {dateFormate?.formatDate(eventDetails?.event_settlement_date)}
                </p>
              </div>
              {/* setelement link */}
            </div>
          </div>
          <div className="d-flex">
            {/*  <div className="p-1 app-bg-secondary">
              <span>Settlement Link: </span>
              <a
                className="app-text-primary"
                href={eventDetails?.source_url}
                target="_blank"
                rel="noreferrer"
              >
                {eventDetails?.source_url}
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
