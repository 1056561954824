import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as loginServe from '../../api/auth';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useDispatch } from 'react-redux';

function ForgetPassword() {
  const [forgetFormValues, setForgetFormValues] = useState({});
  const [forgetToken, setForgetToken] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleValueChange = event => {
    setForgetFormValues({
      ...forgetFormValues,
      [event.target.name]: event.target.value,
    });
  };

  const forgetPassword = async e => {
    e.preventDefault();
    try {
      dispatch(showLoader());

      const data = await loginServe.forgetPassword(forgetFormValues);
      if (data?.status === 200) {
        setMessage(
          'Reset link has been sent to your email address. Please use it to reset your password.',
        );
        setForgetToken(data?.data);
      } else {
        setMessage('Email not found. Please enter a valid email address.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage(error.response.data.error);
      } else {
        console.log(error);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const resetForm = (
    <div className="col-12 col-md-6 col-lg-5 col-xl-3">
      <h2 className="text-center">Forgot Password</h2>
      <form className="mt-3" onSubmit={forgetPassword}>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            required
            type="email"
            onChange={handleValueChange}
            placeholder="example@example.com"
            id="email"
            name="email"
            className="w-100 p-2 mt-2 rounded border border-Secondary"
          />
        </div>
        <div className="mt-3">
          <button className="app-btn app-bg-primary w-100" type="submit">
            Get reset link
          </button>
        </div>
        <div className="mt-3 ">
          {message && <p className="text-danger text-medium ps-2">{message}</p>}
        </div>
        <div className="mt-3 text-center">
          Back to{' '}
          <Link to="/login" className="app-text-primary">
            Login
          </Link>
        </div>
      </form>
    </div>
  );

  const successMessage = (
    <div className="col-12 col-md-6 col-lg-5 col-xl-3">
      <h2 className="text-center">Forgot Password</h2>
      <p className="text-center mt-3">{message}</p>
      <div className="mt-3 text-center">
        Back to{' '}
        <Link to="/login" className="app-text-primary">
          Login
        </Link>
      </div>
    </div>
  );

  return (
    <div className="container h-75">
      <div className="row h-100 justify-content-center align-items-center">
        {forgetToken ? successMessage : resetForm}
      </div>
    </div>
  );
}

export default ForgetPassword;
