import { useState } from 'react';
import * as orderbookServe from '../../api/orderbook';
import { convertInrToUsd } from '../../utils/currencyHandler';
import { useDispatch } from 'react-redux';
import { addToBalance } from '../../store/wallet-slice';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import './portfolio.scss';
import ExitForm from './exitForm';
import { toast } from 'react-toastify';

const OrdersTable = ({
  orders,
  onCancel,
  status,
  onStatusChange,
  refreshOrders,
}) => {
  useDocumentTitle('My Orders');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showExitForm, setShowExitForm] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null); // Store the current order selected for exit

  const handleShowExitForm = (order, refreshOrders) => {
    setCurrentOrder(order);
    setShowExitForm(true);
  };

  const handleExit = order => {
    // Your logic for handling the exit process
    // Maybe making API calls, updating states, etc.

    // Refresh the orders list after successful cancellation
    if (typeof refreshOrders === 'function') {
      refreshOrders();
    }
    // Hide the Exit Form after the process
    setShowExitForm(false);
  };
  const handleCancelOrder = async (order, orderId) => {
    try {
      setLoading(true);

      const userConfirmation = window.confirm(
        'Are you sure you want to cancel this order?',
      );

      if (!userConfirmation) {
        return; // Exit if the user does not confirm
      }

      await orderbookServe.cancelOrder(orderId);

      const refund_amount =
        order.shareAmount * order.sharePrice -
        (order.executedShares * order.averageExecutedPrice || 0);
      const refund_amount_usd = convertInrToUsd(refund_amount);

      dispatch(addToBalance(refund_amount_usd));

      if (onCancel) onCancel(orderId);

      // Display toast on successful cancellation
      toast.success('Order successfully cancelled!');

      // Refresh the orders list after successful cancellation
      if (typeof refreshOrders === 'function') {
        refreshOrders();
      }
    } catch (error) {
      console.error('Failed to cancel order:', error);
      toast.error('Failed to cancel order!'); // Display error toast
    } finally {
      setLoading(false);
    }
  };

  const handleSellOrder = order => {
    navigate(`/orderbook/${order._id}#sell`);
  };

  const formatDate = dateString => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // This will format the time in 12-hour format. Set to false for 24-hour format.
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getIcon = status => {
    switch (status) {
      case 'canceled':
        return <i class="bi bi-x-circle"></i>;
      case 'open':
        return <i class="bi bi-door-open"></i>;
      case 'matched':
        return <i class="bi bi-arrow-through-heart-fill"></i>;
      case 'partially_matched':
        return <i class="bi bi-arrow-through-heart"></i>;
      case 'closed':
        return <i class="bi bi-door-closed"></i>;
      case 'settled':
        return <i class="bi bi-x-circle"></i>;
      case 'sold':
        return <i class="bi bi-bag"></i>;
      case 'onsale':
        return <i class="bi bi-bank"></i>;

      default:
        break;
    }
  };

  const renderOrderRow = (
    order,
    statusOverride,
    shareDisplayOverride,
    keySuffix,
  ) => (
    <tr
      key={order._id + keySuffix}
      className={statusOverride ? `${statusOverride}-row` : ''}
    >
      <td>
        <p>{order?.shareType}</p>
      </td>
      <td>
        <p>{order?.orderType}</p>
      </td>
      <td>
        <p>
          <p>
            {order.status === 'partially_matched'
              ? `${order?.executedShares} matched and ${
                  order?.shareAmount - order?.executedShares
                } unmatched`
              : order.status === 'matched' ||
                  order.status === 'canceled' ||
                  order.status === 'open' ||
                  order.status === 'sold'
                ? `${order?.executedShares} out of ${order?.shareAmount} matched`
                : shareDisplayOverride
                  ? shareDisplayOverride
                  : order?.shareAmount}
            @₹{order?.averageExecutedPrice || order?.sharePrice.toFixed(2)}
          </p>
        </p>
      </td>

      <td>
        <p>
          {order?.status} {getIcon(order?.status)}
        </p>
      </td>
      <td>
        <p>{formatDate(order?.createdAt)}</p>
      </td>
      <td>
        <div className="d-flex justify-content-around">
          {(statusOverride === 'matched' || order.status === 'matched') &&
            order.orderType === 'BUY' && (
              <button onClick={() => handleShowExitForm(order)}>Exit</button>
            )}
          {(statusOverride === 'unmatched' ||
            order.status === 'open' ||
            order.status === 'partially_matched') && (
            <button
              type="button"
              className="btn btn-danger btn-sm"
              disabled={loading}
              onClick={() => handleCancelOrder(order, order._id)}
            >
              Cancel
            </button>
          )}
        </div>
      </td>
    </tr>
  );

  return (
    <div>
      {orders.length > 0 ? (
        <table className="table table-hover table-responsive-md">
          <thead>
            <tr>{/* ... Your table headers */}</tr>
          </thead>
          <tbody>
            {orders.flatMap((order, i) => {
              let rows = [];

              if (order.status === 'partially_matched') {
                rows.push(
                  renderOrderRow(
                    order,
                    'partially_matched',
                    `${order?.executedShares} matched`,
                    '-matched',
                  ),
                );
              } else if (order.status === 'matched') {
                rows.push(
                  renderOrderRow(
                    order,
                    'matched',
                    `${order?.executedShares} matched`,
                    '-matched',
                  ),
                );
                // If the currentOrder is set to this order, then append the ExitForm below this row
                if (
                  currentOrder?._id === order._id &&
                  order.orderType === 'BUY'
                ) {
                  rows.push(
                    <tr key={order._id + '-exitform'}>
                      <td colSpan={6} className="exit-form-cell">
                        <div className="exit-form-wrapper">
                          <ExitForm order={currentOrder} onExit={handleExit} />
                        </div>
                      </td>
                    </tr>,
                  );
                }
              } else {
                rows.push(
                  renderOrderRow(
                    order,
                    order.status,
                    `${order?.executedShares} matched`,
                    '-matched',
                  ),
                );
              }

              return rows;
            })}
          </tbody>
        </table>
      ) : (
        <div className="text-center mt-4">
          <h4>No orders found.</h4>
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
