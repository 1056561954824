import { useState, useEffect } from 'react';
import * as WithdrawalService from '../../api/wallet';
import * as TransactionService from '../../api/transaction';
import * as BetsService from '../../api/event';
import { Link } from 'react-router-dom';
import { updateBalance, subtractFromBalance } from '../../store/wallet-slice';
import Token from '../../utils/tokenHandler';
import * as userDetailsServe from '../../api/userDetails';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';
import * as loginServe from '../../api/auth';
import { showLoader, hideLoader } from '../../store/loader-slice';

const Withdrawal = () => {
  useDocumentTitle('Withdrawal');

  const [balance, setBalance] = useState(0);
  const [mainBalance, setMainBalance] = useState(0);
  const [bonusBalance, setBonusBalance] = useState(0);
  const [winBalance, setWinBalance] = useState(0);

  const [minBet, setMinBet] = useState(0);

  const [betAmount, setBetAmount] = useState(0);
  const [tokenData, setTokenData] = useState();

  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('0');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isBankSubmitted, setIsBankSubmitted] = useState(false);
  const [amountInINR, setAmountInINR] = useState(0); // New state variable

  const [name, setName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [email, setEmail] = useState('');
  const [kycVerified, setKycVerified] = useState(true);
  const [panError, setPanError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [serverError, setServerError] = useState(null); // New state for server error messages

  const [phone, setPhone] = useState({
    countryCode: '+91',
    number: '',
  });

  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    accountName: '',
    ifsc: '',
    branchAddress: '',
  });

  const [submissionStep, setSubmissionStep] = useState(1);

  const navigate = useNavigate();

  const getUserDetails = async () => {
    const user = await loginServe.getBalance();
    console.log('user kyc', user.data.is_kyc_verified);
    if (!user.data.is_kyc_verified) {
      navigate('/kyc'); // replace "/kyc" with your actual KYC page route
    }
  };

  const getBankAccount = async () => {
    try {
      const response = await userDetailsServe.fetchUserBank();

      // Check if account exists
      if (!response.data.accountExists) {
        // Handle the situation when no bank account is found for this user
        navigate('/connect-bank-account');
      } else {
        const { accountNumber, accountName, ifsc, status } = response.data;
        if (accountNumber && accountName && ifsc) {
          setBankDetails({
            accountExists: true,
            accountNumber,
            ifsc,
            accountName,
            status,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWalletBalance = async () => {
    try {
      const response = await WithdrawalService.getWalletBalance();

      const bet_response = await BetsService.getBetAmountForAnUser();

      const total_bet_amount = bet_response.data.data.totalBetAmount;
      setBetAmount(total_bet_amount);
      setMainBalance(response.data.mainBalance);
      setWinBalance(response.data.winBalance);

      setMinBet(Number(response.data.minBet));

      setBalance(
        Number(response.data.mainBalance) + Number(response.data.winBalance),
      );

      setBonusBalance(Number(response.data.bonusBalance));
    } catch (error) {
      console.error(error);
    }
  };

  const handleWithdrawalSubmit = async () => {
    setIsLoading(true);
    setServerError(null); // Clear previous errors

    try {
      const payment_gateway_id = 2;
      if (amountInINR < 100) {
        alert('Minimum withdrawable amount is 100 INR');
        setIsLoading(false);
        return;
      }
      const body = {
        amount,
        amountInINR,
      };
      const response = await TransactionService.placeWithdrawalRequest(body);
      const data = response.data;

      setBalance(balance - amount);

      if (amount <= winBalance) {
        setWinBalance(winBalance - amount);
      } else {
        // If winBalance is not sufficient, deduct remaining from mainBalance
        const remainingAmount = amount - winBalance;

        if (remainingAmount <= mainBalance) {
          setWinBalance(0); // all of winBalance is used
          setMainBalance(mainBalance - remainingAmount);
        }
      }
      dispatch(subtractFromBalance(amount));
      setIsSubmitted(true); // Setting the successful submission to true.
    } catch (error) {
      console.error('error is', error);
      if (error.response && error.response.data) {
        // Display the server's error message
        setServerError('Something went wrong. Please try again.');
      } else {
        // Some other error occurred
        setServerError('An unknown error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Start loader
    dispatch(showLoader());

    // Create an async function within the useEffect to handle async operations
    const fetchData = async () => {
      await getUserDetails();
      await getBankAccount();
      await fetchWalletBalance();
    };

    // Call the function
    fetchData().then(() => {
      // Once all the promises are resolved, hide the loader
      dispatch(hideLoader());
      setIsDataLoaded(true);
    });

    // Since the useEffect cleanup function cannot be async,
    // you should catch any errors in the async function itself
  }, []);

  return (
    <div className="container pb-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center my-4">Withdraw Funds</h2>
          <form
            onSubmit={event => {
              event.preventDefault();
              if (submissionStep === 1) {
                setSubmissionStep(2);
              } else if (submissionStep === 2) {
                handleWithdrawalSubmit();
              }
            }}
          >
            {submissionStep === 1 && (
              <div className="form-group mb-2 py-4">
                <label htmlFor="amount">Amount to Withdraw:</label>

                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    value={amountInINR} // Show INR value in input
                    onChange={e => {
                      setAmountInINR(e.target.value); // Update INR amount
                      setAmount(convertInrToUsd(e.target.value)); // Convert INR to USD and update amount
                    }}
                    onKeyPress={event => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    min="100"
                    step="1"
                    max={convertUsdToInr(balance)}
                    required
                  />
                  <span className="input-group-text">INR</span>
                </div>
                {/*                   <p className="text-muted">Equivalent USD: {amount} USD</p>
                 */}
                <small className="form-text text-muted">
                  Withdrawable balance: ₹{convertUsdToInr(balance)} | Main
                  Amount: ₹{convertUsdToInr(mainBalance)} | Winning Amount: ₹
                  {convertUsdToInr(winBalance)}{' '}
                  {/* {betAmount >= minBet
                    ? "Your bonus amount is withdrawable now."
                    : `(Bonus can be withdrawn once you have placed a total of ₹${convertUsdToInr(
                        minBet
                      )} in predictions across the events.)`} */}
                </small>
              </div>
            )}

            {submissionStep === 2 && (
              <div>
                <div className="bg-light mt-4 p-3">
                  <p>
                    Amount : ₹ <b>{amountInINR} </b>
                  </p>
                  <p>
                    Account Number: <b>{bankDetails.accountNumber}</b>
                  </p>
                  <p>
                    Account Name: <b>{bankDetails.accountName}</b>
                  </p>
                  <p>
                    IFSC Code: <b>{bankDetails.ifsc}</b>
                  </p>
                </div>
              </div>
            )}

            {isSubmitted ? (
              <Link to="/wallet" className="btn btn-primary w-100">
                Go to Wallet
              </Link>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={isLoading}
              >
                {submissionStep === 1 ? 'Next' : 'Confirm Withdrawal'}
              </button>
            )}
          </form>
          <div>
            {serverError && (
              <div className="alert alert-danger">{serverError}</div>
            )}
          </div>

          {isLoading && <div>Loading...</div>}

          {!isLoading && isSubmitted && (
            <div className="alert alert-success">
              Withdrawal request has been placed successfully.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Withdrawal;
