import { useEffect, useRef, useState } from 'react';
import Token from '../../utils/tokenHandler';
import * as loginServe from '../../api/auth';
import * as UserDetailsService from '../../api/userDetails';

import './profile.scss';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { FaCheckCircle } from 'react-icons/fa';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Profile = () => {
  useDocumentTitle('My Profile');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoginWA, setIsLoginWA] = useState(true);

  const defaultImageUrl = '/assets/avathar.png';
  const [userData, setUserData] = useState({
    accountExists: false,
    accountNumber: '',
    accountName: '',
    ifsc: '',
    status: '',
    _id: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImageUrl);
  const [imageFile, setImageFile] = useState();
  const imageInput = useRef();
  const dispatch = useDispatch();
  const token = Token();

  const getUserDetails = async () => {
    try {
      dispatch(showLoader());
      const data = await loginServe.getBalance();
      const {
        _id,
        first_name,
        last_name,
        email,
        mobile,
        profile,
        waName,
        waNumber,
        login_method,
      } = data.data;

      setUserData({
        _id: _id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        waName: waName,
        waNumber: waNumber,
        login_method: login_method,
      });
      if (login_method !== 'wa') {
        setIsLoginWA(false);
      }
      setIsLoading(false); // Set loading to false when data is loaded
      setImagePreviewUrl(profile);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const getUserbankDetails = async () => {
    try {
      const response = await UserDetailsService.fetchUserBank();
      if (!response.data.accountExists) {
        // Handle the situation when no bank account is found for this user
        setUserData({
          accountExists: false,
        });
      } else {
        const { accountNumber, accountName, ifsc, status } = response.data;
        if (accountNumber && accountName && ifsc) {
          setUserData({
            accountExists: true,
            accountNumber,
            ifsc,
            accountName,
            status,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Define functions to handle deletion and addition
  const handleDeleteAccount = async user_bank_id => {
    try {
      dispatch(showLoader());
      await UserDetailsService.updateUserBankDetails(user_bank_id, {
        isActive: false,
      });
      toast.success('Bank account deleted');
      getUserbankDetails(); // Refresh the bank details
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleAddAccount = async () => {
    // Logic for adding a bank account here
  };

  const handleEditPan = () => {
    // Logic for editing PAN number here
  };

  useEffect(() => {
    const userDetails = token.getTokenPayloadData();
    if (userDetails.userId) {
      getUserDetails();
      getUserbankDetails();
    }
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUserData({ ...userData, [name]: value });
  };

  const updateProfile = async e => {
    try {
      e.preventDefault();
      dispatch(showLoader());
      await loginServe.updateUserDetails(userData);
      toast.success('Profile Successfully updated');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  // open image selection
  const openImageSelection = event => {
    if (imagePreviewUrl !== defaultImageUrl) {
      setImagePreviewUrl(defaultImageUrl);
      setImageFile(null);
      return;
    }
    // triggering input element
    imageInput.current.click();
  };

  // handle image change

  const handleImageChange = event => {
    // creating image previewurl
    const imageFile = event.target.files[0];
    setImageFile(imageFile);
    const previewUrl = URL.createObjectURL(imageFile);
    setImagePreviewUrl(previewUrl);
  };

  const updateProfileImage = async e => {
    try {
      e.preventDefault();
      dispatch(showLoader());
      const formData = new FormData();
      formData.append('file', imageFile);
      const data = await loginServe.uploadImage(formData);
      const imageUrl = data?.data?.Location;
      await loginServe.updateUserDetails({ ...userData, profile: imageUrl });
      toast.success('Profile Image updated');
      setImageFile(null);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="container profile">
      <div className="row justify-content-center">
        <div className="col-12 ">
          <div className="border rounded p-3">
            <form
              className="row justify-content-center"
              onSubmit={updateProfile}
            >
              {isLoginWA ? (
                <>
                  <div className="col-12 col-md-6 my-2">
                    <p className="text-secondary mb-0">Name</p>
                    <input
                      placeholder="Name"
                      value={userData.waName}
                      disabled
                      className="w-100 rounded border-0 border-bottom border-dark p-2"
                    />
                  </div>
                  <div className="col-12 col-md-6 my-2">
                    <p className="text-secondary mb-0">WhatsApp Number</p>
                    <input
                      placeholder="Whatsapp Number"
                      value={userData.waNumber}
                      disabled
                      className="w-100 rounded border-0 border-bottom border-dark p-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-4 my-2">
                    <p className="text-secondary mb-0">First Name</p>
                    <input
                      placeholder="FullName"
                      onChange={handleChange}
                      name="first_name"
                      value={userData.first_name}
                      disabled
                      className="w-100 rounded border-0 border-bottom border-dark p-2"
                    />
                  </div>
                  <div className="col-12 col-md-4 my-2">
                    <p className="text-secondary mb-0">Last Name</p>
                    <input
                      placeholder="FullName"
                      name="last_name"
                      onChange={handleChange}
                      value={userData.last_name}
                      disabled
                      className="w-100 rounded border-0 border-bottom border-dark p-2"
                    />
                  </div>
                  <div className="col-12 col-md-6 my-2">
                    <p className="text-secondary mb-0">Email</p>
                    <input
                      placeholder="Email"
                      type="email"
                      value={userData.email}
                      disabled
                      className="w-100 rounded border-0 border-bottom border-dark p-2"
                    />
                  </div>
                </>
              )}
            </form>

            <div className="col-12 my-2">
              {userData.accountExists ? (
                <div className="card">
                  <div className="card-header">My Bank Details</div>
                  <div className="card-body">
                    {userData.status === 'SUCCESS' && (
                      <div>
                        <FaCheckCircle className="ml-2 text-success" />
                        <span className="ml-2">Verified</span>
                      </div>
                    )}

                    <div className="col-12 col-md-6 my-2">
                      <p className="text-secondary mb-0">Account Number</p>
                      <input
                        placeholder="account_number"
                        value={userData.accountNumber}
                        disabled
                        className="w-100 rounded border-0 border-bottom border-dark p-2"
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <p className="text-secondary mb-0">Account Name</p>
                      <input
                        placeholder="account_name"
                        value={userData.accountName}
                        disabled
                        className="w-100 rounded border-0 border-bottom border-dark p-2"
                      />
                    </div>
                    <div className="col-12 col-md-6 my-2">
                      <p className="text-secondary mb-0">IFSC</p>
                      <input
                        placeholder="ifsc"
                        value={userData.ifsc}
                        disabled
                        className="w-100 rounded border-0 border-bottom border-dark p-2"
                      />
                    </div>
                  </div>
                  <button onClick={handleDeleteAccount}>
                    <FaTrash /> Delete Bank Account
                  </button>
                </div>
              ) : (
                <p>NO BANK DETAILS FOUND</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
