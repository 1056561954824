import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as loginServe from '../../api/auth';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useDispatch } from 'react-redux';
import * as validate from '../../utils/validate';

function ResetPassword() {
  const { token } = useParams();
  const [resetFormValues, setResetFormValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [inValidError, setInValideError] = useState([]);

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetToken, setResetToken] = useState(false);

  const handleResetValueChange = event => {
    setResetFormValues({
      ...resetFormValues,
      [event.target.name]: event.target.value,
    });
    validateValues('password', event.target.value);
  };

  const validateValues = (field, value) => {
    switch (field) {
      case 'password':
        setInValideError(validate.validatePassword(value));
        break;
      default:
        setMessage([]);
        break;
    }
  };
  const resetPassword = async e => {
    try {
      dispatch(showLoader());
      e.preventDefault();
      const data = {
        ...resetFormValues,
        resetToken: token,
      };
      const result = await loginServe.reSetPassword(data);

      if (result?.status === 200) {
        setMessage(
          'Your password has been reset successfully. Please login with your new password.',
        );
        setResetToken(true);
      } else {
        setResetToken(true);

        setMessage('Some error. Please try again.');
      }

      //navigate("/login");
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setMessage(error.response.data.error);
        setResetToken(true);
      } else {
        console.log(error);
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  // show hide password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // checking confirm password
  const handlePasswordcheck = event => {
    const password = resetFormValues.password;
    const confirmpassword = event.target.value;
    if (password !== confirmpassword) {
      setIncorrectPassword(true);
      return;
    }
    setIncorrectPassword(false);
  };

  const resetForm = (
    <div className="container-fluid h-75 login">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 col-md-6 col-lg-5 col-xl-3">
          <h2 className="text-center">Set New Password</h2>
          <form className="mt-3" onSubmit={resetPassword}>
            <div>
              <label htmlFor="password">New password</label>
              <div className="position-relative">
                <input
                  required
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleResetValueChange}
                  placeholder="password"
                  id="password"
                  name="password"
                  className="w-100 p-2 mt-2 rounded border border-Secondary"
                />
                <div
                  onClick={handleShowPassword}
                  className="cursor-pointer position-absolute end-0 top-0 mt-3 me-3"
                >
                  {showPassword ? (
                    <i className="bi-eye-slash fs-5" />
                  ) : (
                    <i className="bi bi-eye fs-5" />
                  )}
                </div>
              </div>
            </div>
            {inValidError[0] === 'password' ? (
              <p className="text-danger text-small ps-2">{inValidError[1]}</p>
            ) : (
              ''
            )}
            {/* <div className="mt-3">
            <label htmlFor="confirmpassword">Re-type Password</label>
            <div className="position-relative">
              <input
                required
                type={showPassword ? "text" : "password"}
                onChange={(e) => handlePasswordcheck(e)}
                name="confirmpassword"
                id="confirmpassword"
                className="w-100 p-2 mt-2 rounded border border-Secondary"
              />
              <div
                onClick={handleShowPassword}
                className="cursor-pointer position-absolute end-0 top-0 mt-3 me-3"
              >
                {showPassword ? (
                  <i className="bi-eye-slash fs-5" />
                ) : (
                  <i className="bi bi-eye fs-5" />
                )}
              </div>
            </div>
          </div>
          {incorrectPassword && (
            <p className="text-danger text-small ps-2">
              Passwords do not match.
            </p>
          )} */}

            <div className="mt-3">
              <button
                disabled={
                  incorrectPassword || inValidError.length > 0 ? true : false
                }
                className="app-btn app-bg-primary w-100"
                type="submit"
              >
                Set New Password
              </button>
            </div>
            <div className="mt-3 text-center">
              Back to{' '}
              <Link to="/login" className="app-text-primary">
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  const successMessage = (
    <div className="col-12 col-md-6 col-lg-5 col-xl-3">
      <h2 className="text-center">Forgot Password</h2>
      <p className="text-center mt-3">{message}</p>
      <div className="mt-3 text-center">
        Back to{' '}
        <Link to="/login" className="app-text-primary">
          Login
        </Link>
      </div>
    </div>
  );

  return (
    <div className="container h-75">
      <div className="row h-100 justify-content-center align-items-center">
        {resetToken ? successMessage : resetForm}
      </div>
    </div>
  );
}

export default ResetPassword;
