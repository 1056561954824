import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EventList = ({ events }) => {
  const navigation = useNavigate();
  const { tab } = useParams();

  return (
    <div>
      {events.length > 0 ? (
        <>
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th className="fw-bold">Event Name</th>
                <th className="fw-bold">№ of Buy</th>
                <th className="fw-bold">№ of Sell</th>
                <th className="fw-bold">Closing Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event._id}>
                  <td>
                    <p>{event.event_name}</p>
                  </td>
                  <td>
                    <p>{event?.totalBuy}</p>
                  </td>
                  <td>
                    <p>{event.totalSell}</p>
                  </td>
                  <td>
                    <p>{event.event_end_date || 'N/A'}</p>
                  </td>
                  <td>
                    <div className="btn-view--wrapper">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          console.log(`/portfolio/${tab}/${event._id}`);
                          navigation(`/portfolio/${tab}/${event._id}`);
                        }}
                      >
                        View Orders
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="text-center mt-4">
          <h4>No events found.</h4>
        </div>
      )}
    </div>
  );
};

export default EventList;
