import { Link, useNavigate } from 'react-router-dom';
import * as loginServe from '../../api/auth';
import * as UserService from '../../api/userDetails';

import { useState } from 'react';
import Token from '../../utils/tokenHandler';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/token-slice';
import { showLoader, hideLoader } from '../../store/loader-slice';
import React, { useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import handleReferralCodeIfExists from '../../utils/handleReferralCodeIfExists';

const Login = params => {
  const token = Token();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };
  // Add this function in the Login component to handle the WhatsApp login
  const handleWhatsAppLogin = async () => {
    /* const script = document.createElement("script");
    script.src = "https://otpless.com/auth.js";
    script.async = true;
    document.body.appendChild(script); */
    const otplessWidget = document.getElementById('otpless-floating-button');
    if (otplessWidget) {
      otplessWidget.classList.add('show-widget');
    }

    /*   window.otpless = (otplessUser) => {
      (async function () {
        try {
          console.log(JSON.stringify(otplessUser));
          const result = await loginServe.createWhatsAppUser(otplessUser);
          console.log("login user is:::", result.data);
          token.saveToken(result.data);
          dispatch(setToken());
          navigate(-1);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setErrorMessage("Invalid login. Please try again.");
          } else {
            setErrorMessage(
              "An error occurred while logging in. Please try again later."
            );
          }
        } finally {
          //dispatch(hideLoader());
        }
      })();
    };
    return () => {
      document.body.removeChild(script);
    }; */
  };

  // on values change
  const handleValueChange = event => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  const loginAccount = async event => {
    try {
      event.preventDefault();
      dispatch(showLoader());

      const data = formValues;
      const result = await loginServe.login(data);
      token.saveToken(result.data);
      dispatch(setToken());

      //setIsLoggedIn(true);
      console.log('before');
      window.location.href = '/connect-whatsapp';
      console.log('after');

      //navigate(-1);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Invalid email or password. Please try again.');
      } else if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'An error occurred while logging in. Please try again later.',
        );
      }
    } finally {
      dispatch(hideLoader());
    }
  };
  // show hide password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.body.classList.add('login-page');

    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    // Set the otpless callback function
    window.otpless = async otplessUser => {
      try {
        console.log(JSON.stringify(otplessUser));
        let result;
        if (isLoggedIn) {
          if (otplessUser.waNumber) {
            const payload = {
              waNumber: otplessUser.waNumber,
              first_name: otplessUser.waName,
              waToken: otplessUser.token,
              waTimezone: otplessUser.timezone,
              login_method: 'wa',
            };
            result = await UserService.updateWhatsappNumber(payload); // call your API to link WhatsApp
          }
        } else {
          result = await loginServe.createWhatsAppUser(otplessUser);

          //const result = await loginServe.createWhatsAppUser(otplessUser);
          console.log('login user is:::', result.data);
          token.saveToken(result.data);
          dispatch(setToken());
        }
        handleReferralCodeIfExists();
        navigate(-1);
      } catch (error) {
        console.log('error', error);
        if (error.response && error.response.status === 401) {
          setErrorMessage('Invalid login. Please try again.');
        } else if (error.response && error.response.status === 409) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            'An error occurred while logging in. Please try again later.',
          );
        }
      } finally {
        //dispatch(hideLoader());
      }
    };
  }, []); // add isLoggedIn dependency

  return (
    <div className="container-fluid h-75 login">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-4 col-xl-3">
          <button
            id="whatsapp-login"
            className={`app-btn app-bg-primary w-100 d-flex align-items-center justify-content-center`}
            style={{ fontSize: '20px' }} // Increasing font size
            onClick={handleWhatsAppLogin} // the function to handle WhatsApp login
          >
            <FaWhatsapp className="me-2" size={32} color="white" /> Login with
            WhatsApp
          </button>

          {/* add connect with WhatsApp button if logged in */}
          {isLoggedIn && (
            <button
              className={`app-btn app-bg-primary w-100 d-flex align-items-center justify-content-center`}
              style={{ fontSize: '20px' }}
              onClick={handleWhatsAppLogin}
            >
              <FaWhatsapp className="me-2" size={32} color="white" /> Connect
              with WhatsApp
            </button>
          )}

          <div className="mt-3 text-center">
            <Link
              onClick={toggleLoginForm}
              className="app-text-primary"
              style={{ fontSize: '0.7rem' }} // Add this line
            >
              <AiOutlineMail className="me-2" /> Are you an existing email user?
              Sign in with Email here.
            </Link>
          </div>
          {showLoginForm && (
            <>
              <h2 className="text-center">Login</h2>
              <form className="mt-3" onSubmit={loginAccount}>
                <div>
                  <label htmlFor="email">Email Address</label>
                  <input
                    required
                    type="email"
                    onChange={handleValueChange}
                    placeholder="example@example.com"
                    id="email"
                    name="email"
                    className="w-100 p-2 mt-2 rounded border border-Secondary"
                  />
                </div>

                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="password">Password</label>
                  </div>
                  <div className="position-relative">
                    <input
                      required
                      type="password"
                      onChange={handleValueChange}
                      id="password"
                      name="password"
                      className="w-100 p-2 mt-2 rounded border border-Secondary"
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    className="app-btn app-bg-primary w-100"
                    type="submit"
                  >
                    Continue
                  </button>
                  {errorMessage && (
                    <div className="text-danger mt-3">{errorMessage}</div>
                  )}
                </div>
                {/* sign up link */}
                <div className="mt-3 text-center">
                  <Link to="/forget-password" className="app-text-primary">
                    Forgot password?
                  </Link>
                </div>
                {/* <div className="mt-3 text-center">
              New user?{" "}
              <Link to="/signup" className="app-text-primary">
                {" "}
                Create an account
              </Link>
            </div> */}
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
