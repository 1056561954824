import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as TransactionService from '../../api/transaction';
import { BiClipboard } from 'react-icons/bi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateBalance } from '../../store/wallet-slice';
import { useDispatch } from 'react-redux';
import * as WithdrawalService from '../../api/wallet';
import QrCodeGenerator from '../../components/common/qrcode';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Deposit = () => {
  useDocumentTitle('Deposit');

  const [selectedCurrency, setSelectedCurrency] = useState('busdbsc');
  const [usdAmount, setUsdAmount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('waiting');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);
    // Submit form data to backend API and handle response
    try {
      const body = {
        price_amount: usdAmount,
        price_currency: 'usd',
        pay_currency: selectedCurrency,
        payment_gateway_id: 1,
      };
      const response = await TransactionService.createTransaction(body);
      const data = response.data;
      setTransactionData(data);
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 10000); // Clear copied text after 1 second
  };
  const handleCheckPaymentStatus = async () => {
    try {
      const response = await TransactionService.getTxById(transactionData?._id);
      const data = response.data;
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      setPaymentStatus(data.paymentStatus);
      if (data.paymentStatus === 'finished') {
        //setPaymentStatus("Your payment is successful");
        //update wallet balance
        const wallet = await WithdrawalService.getWalletBalance();
        const wallet_balance =
          wallet.data.mainBalance +
          wallet.data.bonusBalance +
          wallet.data.winBalance;
        dispatch(updateBalance(wallet_balance));
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getCurrencySymbol = selectedCurrency => {
    switch (selectedCurrency) {
      case 'usdterc20':
      case 'usdttrc20':
        return 'USDT';
      case 'busdbsc':
        return 'BUSD';
      case 'trx':
        return 'TRX';
      case 'SHIBBSC':
        return 'SHIB';
      default:
        return selectedCurrency;
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 my-3">
          <div className="alert alert-info text-center">
            You will receive 20% bonus on your first deposit.
          </div>
        </div>
        <div className={`col-12   ${isSubmitted ? 'd-none' : ''}`}>
          <h2 className="text-center my-4">Select currency to deposit</h2>
          <div className="btn-group w-100">
            <button
              type="button"
              className={`btn btn-block btn-sm ${
                selectedCurrency === 'busdbsc'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => setSelectedCurrency('busdbsc')}
              style={{ fontSize: '0.9rem' }}
            >
              BUSD - BSC
            </button>

            <button
              type="button"
              className={`btn btn-block btn-sm ${
                selectedCurrency === 'usdttrc20'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => setSelectedCurrency('usdttrc20')}
              style={{ fontSize: '0.9rem' }}
            >
              USDT - TRC20
            </button>

            <button
              type="button"
              className={`btn btn-block btn-sm ${
                selectedCurrency === 'trx'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => setSelectedCurrency('trx')}
              style={{ fontSize: '0.9rem' }}
            >
              TRX - TRON
            </button>
            <button
              type="button"
              className={`btn btn-block btn-sm ${
                selectedCurrency === 'doge'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => setSelectedCurrency('doge')}
              style={{ fontSize: '0.9rem' }}
            >
              Doge - Doge
            </button>
            <button
              type="button"
              className={`btn btn-block btn-sm ${
                selectedCurrency === 'SHIBBSC'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}
              onClick={() => setSelectedCurrency('SHIBBSC')}
              style={{ fontSize: '0.9rem' }}
            >
              SHIB - BSC
            </button>
            {/* <button
        type="button"
        className={`btn btn-block btn-sm ${
          selectedCurrency === "usdterc20"
            ? "btn-primary"
            : "btn-outline-primary"
        }`}
        onClick={() => setSelectedCurrency("usdterc20")}
        style={{ fontSize: "0.9rem" }}
      >
        USDT - ERC20
      </button> */}
          </div>
        </div>
      </div>

      <div className="row justify-content-center my-4">
        <div className={`col-12 col-md-6 ${isSubmitted ? 'd-none' : ''}`}>
          <h2 className="text-center my-4">Enter Amount in USD</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <span className="input-group-text">$</span>
              <input
                type="number"
                className="form-control"
                value={usdAmount}
                onChange={e => setUsdAmount(e.target.value)}
                onClick={e => (e.target.value = '')}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                min="5"
                max="500"
                pattern="[5-9]|[1-4][0-9]|[5][0-9][0-9]"
                required
              />
            </div>

            <button type="submit" className="btn btn-primary w-100">
              Get Deposit Information
            </button>
          </form>
        </div>
      </div>

      {!transactionData && isLoading && (
        <div className="text-center my-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div>Getting deposit information</div>
        </div>
      )}

      {isSubmitted && (
        <div className="row justify-content-center my-4">
          <div className="col-12 ">
            <div className="card">
              <div className="card-header">Deposit Information</div>
              <div className="card-body">
                <p>
                  You need to send{' '}
                  <b>
                    {transactionData?.payAmount}{' '}
                    {getCurrencySymbol(selectedCurrency)}{' '}
                  </b>{' '}
                  to this address.
                </p>
                <p>
                  <QrCodeGenerator address={transactionData?.payAddress} />
                </p>

                <div className="mb-3 d-flex align-items-center">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control small-text"
                      value={transactionData?.payAddress}
                      disabled
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        letterSpacing: '1.5px',
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={event => {
                        navigator.clipboard.writeText(
                          transactionData?.payAddress,
                        );
                        const target = event.target;
                        target.innerText = 'Copied';
                      }}
                    >
                      <i className="bi bi-clipboard"></i>
                    </button>
                  </div>
                </div>

                <p style={{ color: '#555' }}>
                  Send only{' '}
                  <span style={{ color: '#f8b739', fontWeight: '600' }}>
                    {getCurrencySymbol(selectedCurrency)}{' '}
                  </span>
                  on{' '}
                  <span style={{ color: '#f8b739', fontWeight: '600' }}>
                    {transactionData?.network}{' '}
                  </span>
                  network to the above address.
                </p>

                {/* <p>Your wallet will receive in USD: ${usdAmount}</p> */}
                {/* <div className="d-flex align-items-center">
                  <span className="me-2">Payment Status:</span>
                  {paymentStatus === "waiting" && (
                    <i className="bi bi-hourglass-split me-2"></i>
                  )}
                  {paymentStatus === "success" && (
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                  )}

                  <span>{paymentStatus}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {isSubmitted && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Back to Wallet
            </Link>
          </div>
        </div>
      )} */}
      <div className="d-flex justify-content-center">
        {isSubmitted && (
          <div className="d-flex align-items-center flex-column">
            <div className="d-flex align-items-center mb-4 ">
              <span className="me-2">Payment Status:</span>
              {paymentStatus === 'waiting' && (
                <i className="bi bi-hourglass-split me-2"></i>
              )}
              {paymentStatus === 'confirming' && (
                <i className="bi bi-clock me-2"></i>
              )}
              {paymentStatus === 'confirmed' && (
                <i className="bi bi-clock me-2"></i>
              )}
              {paymentStatus === 'finished' && (
                <i className="bi bi-check-circle-fill text-success me-2"></i>
              )}
              {paymentStatus === 'partially_paid' && (
                <i className="bi bi-exclamation-triangle-fill text-warning me-2"></i>
              )}
              {paymentStatus === 'failed' && (
                <i className="bi bi-x-circle-fill text-danger me-2"></i>
              )}
              {paymentStatus === 'expired' && (
                <i className="bi bi-x-circle-fill text-danger me-2"></i>
              )}
              <span>{paymentStatus}</span>
            </div>
            {paymentStatus === 'failed' && (
              <p className="text-danger">
                Payment failed? Don't worry, we'll get you sorted! Let us know
                if you were charged and we'll sort it out.
              </p>
            )}
            {paymentStatus === 'confirming' && (
              <p className="text-muted mb-0">
                We have received your transaction on the chain and it's being
                processed. Hang tight!
              </p>
            )}
            {paymentStatus === 'confirmed' && (
              <p className="text-muted mb-0">
                Great news! Your payment has been confirmed and we're processing
                it now.
              </p>
            )}
            {paymentStatus === 'finished' && (
              <p className="text-success">
                Payment successful! Woohoo! Your wallet has been credited.
              </p>
            )}
            {paymentStatus === 'partially_paid' && (
              <p className="text-success">
                Looks like you did a partial payment. Don't worry, we've
                credited your wallet with the amount you paid.
              </p>
            )}
            {paymentStatus === 'expired' && (
              <p className="text-danger">
                This payment detail has expired now. Create a new deposit.
              </p>
            )}
          </div>
        )}
      </div>
      {isSubmitted &&
        paymentStatus !== 'finished' &&
        paymentStatus !== 'partially_paid' &&
        paymentStatus !== 'failed' &&
        paymentStatus !== 'expired' && (
          <div className="row justify-content-center my-4">
            <div className="col-12 col-md-6">
              <button
                type="button"
                className="btn btn-outline-primary w-100"
                onClick={() => {
                  setIsLoading(true);
                  handleCheckPaymentStatus();
                }}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Checking payment status...
                  </>
                ) : (
                  <>
                    {paymentStatus === 'waiting'
                      ? `I just sent ${transactionData?.payAmount} ${selectedCurrency}`
                      : 'Click here to check status'}
                  </>
                )}
              </button>

              <div className="mt-2 text-center">
                <p>
                  If you face any issues with deposits, please{' '}
                  <Link to="/contact">contact us</Link>.
                </p>
              </div>
            </div>
          </div>
        )}
      {isSubmitted && paymentStatus === 'finished' && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Go to Wallet
            </Link>
            <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{' '}
                <Link to="/contact">contact us</Link>.
              </p>
            </div>
          </div>
        </div>
      )}
      {isSubmitted && paymentStatus === 'partially_paid' && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Go to Wallet
            </Link>
            <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{' '}
                <Link to="/contact">contact us</Link>.
              </p>
            </div>
          </div>
        </div>
      )}
      {isSubmitted && paymentStatus === 'failed' && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Go to Wallet and try again
            </Link>
            <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{' '}
                <Link to="/contact">contact us</Link>.
              </p>
            </div>
          </div>
        </div>
      )}
      {isSubmitted && paymentStatus === 'expired' && (
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-6">
            <Link to="/wallet" className="btn btn-primary w-100">
              Go to Wallet and create a new deposit
            </Link>
            <div className="mt-2 text-center">
              <p>
                If you face any issues with deposits, please{' '}
                <Link to="/contact">contact us</Link>.
              </p>
            </div>
          </div>
        </div>
      )}
      {/*  {isLoading && (
        <div className="text-center my-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div>Checking the status</div>
        </div>
      )} */}
    </div>
  );
};

export default Deposit;
