import { createSlice } from '@reduxjs/toolkit';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    balance: 0,
  },
  reducers: {
    updateBalance: (state, action) => {
      const roundedBalance = parseFloat(action.payload).toFixed(4);
      state.balance = roundedBalance;
    },
    addToBalance: (state, action) => {
      const roundedAmount = parseFloat(action.payload).toFixed(4);
      state.balance = (
        parseFloat(state.balance) + parseFloat(roundedAmount)
      ).toFixed(4);
    },
    subtractFromBalance: (state, action) => {
      const roundedAmount = parseFloat(action.payload).toFixed(4);
      state.balance = (
        parseFloat(state.balance) - parseFloat(roundedAmount)
      ).toFixed(4);
    },
  },
});

export const walletInitialState = {
  balance: 0,
};

export const { updateBalance, addToBalance, subtractFromBalance } =
  walletSlice.actions;
export const walletActions = walletSlice.actions;
export default walletSlice.reducer;
