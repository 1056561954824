import { useState } from 'react';
import './pagination.scss';

export const Pagination = ({ meta, handlePagination }) => {
  const [optionPerPage, setOptionPerPage] = useState([
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
  ]);

  const { page, totalPages, perPage, totalRecords } = meta;
  const pageToNumber = Number(page);
  const totalPagesToNumber = Number(totalPages);

  return (
    <>
      {totalRecords > optionPerPage[0].value && (
        <div className="wrapper-pagination">
          <nav aria-label="Page navigation example ">
            <ul className="pagination">
              <li
                className={`page-item page-link ${
                  pageToNumber === 1 ? 'disabled' : ''
                }`}
                onClick={() => handlePagination(pageToNumber - 1, perPage)}
              >
                Previous
              </li>
              {Array.from({ length: totalPages }, (v, k) => k + 1).map(
                renderPage => {
                  if (
                    pageToNumber - 2 <= renderPage &&
                    pageToNumber + 2 >= renderPage
                  ) {
                    return (
                      <li
                        className={`page-item ${
                          pageToNumber === renderPage ? 'active' : ''
                        } page-link `}
                        key={renderPage}
                        onClick={() => handlePagination(renderPage, perPage)}
                      >
                        {renderPage}
                      </li>
                    );
                  }
                },
              )}
              <li
                className={`page-item page-link ${
                  pageToNumber === totalPagesToNumber ? 'disabled' : ''
                }`}
                onClick={() => handlePagination(pageToNumber + 1, perPage)}
              >
                Next
              </li>
            </ul>
          </nav>

          <select
            className="form-select per-page-select"
            value={perPage}
            onChange={e => {
              console.log(e.target.value);
              handlePagination(1, e.target.value);
            }}
          >
            {optionPerPage.map(({ value, text }) => (
              <option key={`${value}` + 'per-page'} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};
