import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import * as eventServe from '../../api/event';
import * as loginServe from '../../api/auth';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../store/loader-slice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as dateFormate from '../../utils/dateformate';
import * as walletService from '../../api/wallet';
import { Button, Form } from 'react-bootstrap';
import { updateBalance, subtractFromBalance } from '../../store/wallet-slice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { convertUsdToInr, convertInrToUsd } from '../../utils/currencyHandler';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useLocation } from 'react-router-dom';

const ExitForm = ({ order, onExit }) => {
  const dispatch = useDispatch();

  const [outcomeSelect, setOutcomeSelect] = useState(order.shareType); // Pre-selected based on order.shareType
  const [numShares, setNumShares] = useState(1); // Default to 1 share
  const [sharePrice, setSharePrice] = useState(order.sharePrice); // Use the order's share price by default
  const [maxNumShares, setMaxNumShares] = useState(order.availableShares); // Derived from order.executedShares

  // Additional states and functions (e.g., handleNumSharesChange, incrementShares, decrementShares) would be needed

  const handleExit = async e => {
    e.preventDefault();

    // Check conditions if any
    // ...

    dispatch(showLoader());

    // Create the exit data object similar to the sellOrder function
    const data = {
      outcome: outcomeSelect,
      numShares,
      sharePrice: sharePrice,
    };

    try {
      const orderExited = await eventServe.sellOrder(order._id, data);
      if (orderExited) {
        console.log('Exit order executed');
        // Additional logic after successful exit if any
        // ...
        toast.success('Your exit order has been placed.');
        onExit(); // Callback to parent
      }
    } catch (error) {
      console.log(error);
      toast.error(
        'Some error occurred. Please wait a while before trying again.',
      );
    } finally {
      dispatch(hideLoader());
      setNumShares(1);
      setSharePrice(order.sharePrice); // Reset to order's share price
    }
  };

  const incrementShares = () => {
    updateNumShares(numShares + 1);
  };

  const decrementShares = () => {
    if (numShares > 1) {
      updateNumShares(numShares - 1);
    }
  };

  const handleSharePriceChange = e => {
    setSharePrice(e);
  };

  const handleNumSharesChange = e => {
    const newValue = e.target.value;
    updateNumShares(newValue);
  };

  const updateNumShares = newValue => {
    if (newValue >= 1 && newValue <= maxNumShares) {
      setNumShares(newValue);
    } else {
      if (newValue < 1) {
        setNumShares(1);
      } else if (newValue > maxNumShares) {
        setNumShares(maxNumShares);
      }
    }
  };
  return (
    <div className="exit-form-container">
      <h4>Exit {outcomeSelect} order</h4>

      <Form onSubmit={handleExit}>
        {/* Number of shares */}
        <Form.Group className="mb-3 text-center">
          <Form.Label className="fw-bold mb-0 text-center">
            Number of Shares (max {maxNumShares})
          </Form.Label>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <button
              type="button"
              className="rounded-circle"
              onClick={decrementShares}
            >
              -
            </button>
            <input
              type="number"
              value={numShares}
              onChange={handleNumSharesChange}
              min={1}
              max={maxNumShares}
              className="mx-2 text-center"
              style={{ width: '40px' }}
            />
            <button
              type="button"
              className="rounded-circle"
              onClick={incrementShares}
            >
              +
            </button>
          </div>
        </Form.Group>

        {/* Share price using slider */}
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold mb-0">Share Price</Form.Label>
          <Slider
            min={0.5}
            max={9.5}
            step={0.5}
            value={sharePrice}
            onChange={handleSharePriceChange}
          />
          <span className="ml-10">₹{Number(sharePrice).toFixed(2)}</span>
        </Form.Group>

        {/* Submit button */}
        <Button
          variant="primary"
          type="submit"
          className="w-100 mt-2"
          // Other conditions...
        >
          Exit Order
        </Button>
      </Form>
    </div>
  );
};

export default ExitForm;
