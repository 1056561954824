import apiClient from './apiClient';

export const getMyOrders = status => {
  return apiClient.get(`/orderbook/user`, {
    params: {
      status,
    },
  });
};

export const cancelOrder = orderId => {
  return apiClient.put(`/orderbook/cancel/${orderId}`);
};

export const getOrdersByEventForUser = (eventId, page, perPage) => {
  return apiClient.get(`/orderbook/getOrdersByEvent/${eventId}`, {
    params: {
      page,
      perPage,
    },
  });
};
