import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const query = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (Object.keys(meta).length === 0) return;

    setSearchParams({ page: meta.page, perPage: meta.perPage });
  }, [meta]);

  return { setMeta, meta, query };
};
