import apiClient from './apiClient';

export const getWalletBalance = () => {
  return apiClient.get(`wallet/balance`);
};
export const updateWalletBalance = data => {
  return apiClient.put(`wallet/updatebalance`, data);
};

export const placeBet = data => {
  return apiClient.put(`wallet/placebet`, data);
};
