import apiClient from './apiClient';

export const createTransaction = data => {
  return apiClient.post(`/transactions/create`, data);
};
export const placeWithdrawalRequest = data => {
  return apiClient.post(`/transactions/createWithdraw`, data);
};

export const getTxById = id => {
  return apiClient.get(`/transactions/${id}`);
};

export const getAllTxsByUserId = ({ page, perPage, isDeposit }) => {
  return apiClient.get(`transactions/userTxs`, {
    params: { page, perPage, isDeposit },
  });
};

export const updateTransaction = (id, data) => {
  return apiClient.put(`/transactions/${id}`, data);
};
