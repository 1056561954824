import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

const QRCodeComponent = ({ address }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    QRCode.toCanvas(canvas, address, function (error) {
      if (error) console.error(error);
      console.log('QR code generated!');
    });
  }, [address]);

  return <canvas id="walletQrCode" ref={canvasRef}></canvas>;
};

export default QRCodeComponent;
