import { useState } from 'react';
import * as userDetailsServe from '../../api/userDetails';

function KYCForm() {
  const [panNumber, setPanNumber] = useState('');
  const [panError, setPanError] = useState(null);
  const [aadharNumber, setAadharNumber] = useState('');
  const [aadharError, setAadharError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [kycSuccess, setKycSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    if (
      panError ||
      aadharError ||
      panNumber === '' ||
      aadharNumber === '' ||
      firstName === '' ||
      lastName === ''
    ) {
      return;
    }
    setIsLoading(true); // Start loading

    const payload = {
      pan: panNumber,
      aadharNumber: aadharNumber,
      first_name: firstName,
      last_name: lastName,
    };

    try {
      const result = await userDetailsServe.updateKycDetails(payload);
      console.log(result.data);
      setKycSuccess(true); // update the state if KYC update is successful
      setIsLoading(false); // Stop loading when error occurs
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'Failed to verify your KYC details. Please try again with correct information.',
      );
      setIsLoading(false); // Stop loading when error occurs
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          {kycSuccess ? (
            <>
              <h2 className="text-center mb-3">KYC update successful!</h2>
              <button
                className="btn btn-primary w-100"
                onClick={() => (window.location.href = '/connect-bank-account')}
              >
                Proceed to add Bank account
              </button>
            </>
          ) : (
            <>
              <h2 className="text-center ">Please complete your KYC</h2>
              <div className="d-flex justify-content-center">
                <hr className="col-sm-9" style={{ borderColor: 'blue' }} />
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group row mb-3">
                  <label
                    htmlFor="panNumber"
                    className="col-sm-3 col-form-label"
                  >
                    PAN:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="panNumber"
                      value={panNumber}
                      onChange={e => {
                        const inputValue = e.target.value.toUpperCase();
                        setPanNumber(inputValue);

                        if (inputValue.length === 10) {
                          if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(inputValue)) {
                            setPanError('Please enter a valid PAN number.');
                          } else {
                            setPanError(null);
                          }
                        } else {
                          setPanError('PAN number must be 10 characters.');
                        }
                      }}
                      maxLength={10}
                      required
                    />

                    {panError && <div style={{ color: 'red' }}>{panError}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label
                    htmlFor="aadharNumber"
                    className="col-sm-3 col-form-label"
                  >
                    Aadhar:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="aadharNumber"
                      value={aadharNumber}
                      onChange={e => {
                        const inputValue = e.target.value;
                        setAadharNumber(inputValue);

                        if (inputValue.length === 12) {
                          if (!/^\d{12}$/.test(inputValue)) {
                            setAadharError(
                              'Please enter a valid Aadhar number.',
                            );
                          } else {
                            setAadharError(null);
                          }
                        } else {
                          setAadharError('Aadhar number must be 12 digits.');
                        }
                      }}
                      maxLength={12}
                      required
                    />

                    {aadharError && (
                      <div style={{ color: 'red' }}>{aadharError}</div>
                    )}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label
                    htmlFor="firstName"
                    className="col-sm-3 col-form-label"
                  >
                    First Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="lastName" className="col-sm-3 col-form-label">
                    Last Name:
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={
                    panError ||
                    aadharError ||
                    panNumber === '' ||
                    aadharNumber === '' ||
                    firstName === '' ||
                    lastName === ''
                  }
                >
                  {isLoading ? 'Verifying....' : 'Submit Kyc details'}
                </button>
              </form>
              {errorMessage && (
                <div style={{ color: 'red' }}>{errorMessage}</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default KYCForm;
