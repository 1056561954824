import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AuthService from '../api/auth';

const EmailVerification = ({ match }) => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyEmail() {
      try {
        //const token = new URLSearchParams(window.location.search).get('token');
        //const token = match.params.token;
        const token = window.location.pathname.split('/').pop(); // get the token using split

        const response = await AuthService.verifyEmail(token);

        setMessage(response.data.message);

        if (response.data.user && response.data.user.is_email_verified) {
          navigate('/login');
        }
      } catch (error) {
        setMessage('Error verifying email. Please try again later.');
      }
    }

    verifyEmail();
  }, [match, navigate]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{message}</p>
      {message === 'Email verification successful' && (
        <button onClick={() => navigate('/login')}>Proceed with Login</button>
      )}
    </div>
  );
};

export default EmailVerification;
