import apiClient from './apiClient';

export const updateWhatsappNumber = data => {
  return apiClient.put(`users/updateWhatsappNumber`, data);
};
export const updateUserDetails = data => {
  return apiClient.put(`users/updateUser`, data);
};
export const createUserBank = data => {
  return apiClient.post(`userbank/create`, data);
};
export const fetchUserBank = () => {
  return apiClient.get(`userbank/getUserBank`);
};
export const fetchUserINRStatus = () => {
  return apiClient.get(`users/getUserINRstatus`);
};
export const checkVerificationStatusAPI = id => {
  return apiClient.get(`userbank/checkVerificationStatus/${id}`);
};
export const updateUserBankDetails = (id, data) => {
  return apiClient.put(`userbank/updateUserBank/${id}`, data);
};
export const updateKycDetails = data => {
  return apiClient.put(`users/updateKycDetails`, data);
};
