import { useState, useEffect } from 'react';
import * as eventServe from '../../api/event';
import './portfolio.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { usePagination } from '../../hooks/usePagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';
// ... other imports
import EventList from './eventList';
import { BetsTable } from './betsTable';
// import the EventList component
import moment from 'moment';
import { Pagination } from '../../components/pagination/pagination';

const DEFAULT_PARAMS = {
  page: 1,
  perPage: 5,
  totalPages: 1,
  totalRecords: 0,
};

const Portfolio = () => {
  const [events, setEvents] = useState([]); // State to hold events data
  const [polls, setPolls] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(true);
  const { setMeta, meta, query } = usePagination();
  const [status, setStatus] = useState('open');
  const navigation = useNavigate();
  const { tab } = useParams();

  useDocumentTitle('My Predictions');

  const formatDateToLocal = date => {
    // Here you can use your logic or libraries like moment.js to format the date
    return moment(date).format('hh:mmA'); // 10:30AM
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);

      switch (tab) {
        case 'events':
          fetchEvents({
            page: query?.page ? query?.page : DEFAULT_PARAMS.page,
            perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
          }); // Invoke the function to fetch events
          break;

        default:
          fetchPolls({
            status,
            page: query?.page ? query?.page : DEFAULT_PARAMS.page,
            perPage: query?.perPage ? query?.perPage : DEFAULT_PARAMS.perPage,
          });
          break;
      }
    }
  }, [tab]); // Dependency array is empty, so this effect will only run once, similar to componentDidMount()

  const fetchPolls = async ({ status, page, perPage }) => {
    setLoading(true);
    try {
      const { data } = await eventServe.getMyBets(status, page, perPage);

      const formattedBets = data.result.map(bet => {
        // Sum up bet_amount

        // Make sure event_id exists and has an event_name property before attempting to format it
        if (bet.event_id && bet.event_id.event_name) {
          const formattedEventName = bet.event_id.event_name
            .replace(
              '{endDate}',
              formatDateToLocal(bet.event_id.event_end_date),
            )
            .replace(
              '{settlementDate}',
              formatDateToLocal(bet.event_id.event_settlement_date),
            );

          return {
            ...bet,
            event_id: { ...bet.event_id, event_name: formattedEventName },
          };
        }

        return bet; // if event_id or event_name doesn't exist, return the original bet object
      });

      // Update state with summed up bet_amount and reversed list of formatted bets

      setPolls(formattedBets?.reverse());
      setMeta(data?.pagination);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    setLoading(false);
  };

  const fetchEvents = async ({ page, perPage }) => {
    setLoading(true);
    try {
      const { data } = await eventServe.getAllEventsForUser(
        'open',
        page,
        perPage,
      );

      if (data.result) {
        setEvents(data.result); // set fetched data to events state
        setMeta(data?.pagination);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    setLoading(false);
  };

  const changeTab = key => {
    setActiveTab(key);
    navigation(`/portfolio/${key}`);
  };

  const handlePagination = (page, perPage) => {
    switch (tab) {
      case 'events':
        fetchEvents({
          page,
          perPage,
        }); // Invoke the function to fetch events
        break;

      default:
        fetchPolls({
          status,
          page,
          perPage,
        });
        break;
    }
  };

  const handleStatusChange = status => {
    fetchPolls({ status, page: 1, perPage: query?.perPage });
    setStatus(status);
  };

  return (
    <div className={`container portfolio`}>
      <ul class="nav nav-tabs">
        {[
          { name: 'Polls', key: 'polls' },
          { name: 'Events', key: 'events' },
        ].map(el => {
          return (
            <li className="nav-item" key={el.key}>
              <button
                className={`nav-link ${activeTab === el.key ? 'active' : ''}`}
                onClick={() => changeTab(el.key)}
              >
                {el.name}
              </button>
            </li>
          );
        })}
      </ul>
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {tab === 'events' ? (
            <EventList
              events={events}
              meta={meta}
              handlePagination={handlePagination}
            />
          ) : (
            <>
              <BetsTable
                bets={polls}
                status={status}
                onStatusChange={handleStatusChange}
              />
            </>
          )}

          {Object.keys(meta).length > 0 && (
            <div className="pagination">
              <Pagination meta={meta} handlePagination={handlePagination} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Portfolio;
