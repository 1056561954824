/* export const formatDate = (dateString, type) => {
    const date = new Date(dateString);
    const now = new Date();
  
    const options = { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    if (type === 'small') {
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    }
  
    const diff = date.getTime() - now.getTime();
  
    let days;
    if (diff < 0) {
      days = Math.floor(Math.abs(diff) / (1000 * 60 * 60 * 24));
    } else if (diff < 1000 * 60 * 60 * 24) {
      days = 0;
    } else {
      days = Math.floor(diff / (1000 * 60 * 60 * 24));
    }
  
    if (days === 0) {
      return `${formattedDate} | Today `;
    } else if (diff > 0) {
      return `${formattedDate} | Ends in ${days} day${days > 1 ? 's' : ''}`;
    } else {
      return `${formattedDate} | ${days} day${days > 1 ? 's' : ''} ago`;
    }
  };
   */

export const formatDate = (dateString, type) => {
  const date = new Date(dateString);
  const now = new Date();

  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  if (type === 'small') {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const diff = date.getTime() - now.getTime();

  let days;
  let hours;
  if (diff < 0) {
    days = Math.floor(Math.abs(diff) / (1000 * 60 * 60 * 24));
  } else if (diff < 1000 * 60 * 60 * 24) {
    days = 0;
    hours = Math.floor(diff / (1000 * 60 * 60));
  } else {
    days = Math.floor(diff / (1000 * 60 * 60 * 24));
  }

  if (days === 0) {
    if (diff < 0) {
      hours = Math.floor(Math.abs(diff) / (1000 * 60 * 60));
      return `${formattedDate} |  ${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${formattedDate} | Ends in ${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${formattedDate} | Less than an hour remaining`;
    }
  } else if (diff > 0) {
    return `${formattedDate} | Ends in ${days} day${days > 1 ? 's' : ''}`;
  } else {
    return `${formattedDate} | ${days} day${days > 1 ? 's' : ''} ago`;
  }
};

export const formatTopDate = dateString => {
  const date = new Date(dateString);
  const now = new Date();

  const diff = date.getTime() - now.getTime();

  if (diff < 0) {
    return 'Event closed';
  }

  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (diffDays > 0) {
    const daysText = diffDays > 1 ? 'days' : 'day';
    return `${diffDays} ${daysText} left`;
  } else if (diffHours > 0) {
    const hoursText = diffHours > 1 ? 'hours' : 'hour';
    return `${diffHours} ${hoursText} left`;
  } else if (diffMinutes > 0) {
    const minutesText = diffMinutes > 1 ? 'minutes' : 'minute';
    return `${diffMinutes} ${minutesText} left`;
  } else {
    return 'Event ending soon';
  }
};

export const compareDates = dateString => {
  const inputDate = new Date(dateString);
  const today = new Date();
  const diff = inputDate.getTime() - today.getTime();

  if (diff > 0) {
    return 'greater';
  } else if (diff < 0) {
    return 'lesser';
  } else {
    return 'equal';
  }
};
